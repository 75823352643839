import React from 'react';
import { Card, CardContent } from '@mui/material';
import {
  Edit,
  SimpleForm,
  NumberInput,
  TextInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import { useResourceAccess } from '../../hooks/useAccess';

const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ApiConfig: React.FC = () => {
  const { SettingsDetailsEdit } = useResourceAccess();
  const isRo = !SettingsDetailsEdit();
  return (
    <Card>
      <CardContent>
        <Edit
          resource="config"
          id={''}
          redirect={false}
          actions={false}
          title=" (Credit Card)"
          mutationMode={'pessimistic'}
        >
          <SimpleForm toolbar={!isRo ? <CustomToolbar /> : false}>
            <NumberInput source="interest" disabled={isRo} />
            <TextInput source="adequateExplanationsDocumentS3Key" disabled={isRo} />
            <BooleanInput source="newCardsCreditBuilderOn" disabled={isRo} />
            <BooleanInput source="autoPaymentOn" label="Auto Lock On" disabled={isRo} />
            <ReferenceArrayInput
              source="staff"
              reference="usermanagement"
              filter={{ searchOnly: true }}
              disabled={isRo}
            >
              <AutocompleteArrayInput
                filterToQuery={(t: string) => ({ email: t })}
                optionText={'email'}
                disabled={isRo}
              />
            </ReferenceArrayInput>
            <BooleanInput source="authEnsureMFAEnabled" disabled={isRo} />
            <NumberInput source="authEnsureMFAForGetPinEveryMinutes" disabled={isRo} />
            <NumberInput source="authEnsureMFAForGetSecretEveryMinutes" disabled={isRo} />
            <NumberInput source="authEnsureMFAForSetPinEveryMinutes" disabled={isRo} />
            <NumberInput source="authEnsureMFAForUpdateKBAEveryMinutes" disabled={isRo} />
          </SimpleForm>
        </Edit>
      </CardContent>
    </Card>
  );
};

export default ApiConfig;
